a[href="http://www.webks.de"]:hover {
  color: #2e90d0 !important;
}

a[href="http://www.drowl.de"]:hover {
  color: #83b441 !important;
}

.webks-blue {
  color: #2e90d0;
}

.drowl-green {
  color: #83b441;
}

body.maintenance-page {
  background: url("../../images/gear_bg.png") center bottom no-repeat #cccccc !important;
  color: #222;
  height: 100%;
  margin: 0;
  padding: 0;
}
body.maintenance-page h1,
body.maintenance-page h2 {
  color: #888888;
}
body.maintenance-page #maintenance-branding {
  padding-top: 50px;
  text-align: center;
}
body.maintenance-page #maintenance-main-content {
  background-color: #cccccc;
  border-color: #2e90d0;
  border-image: none;
  border-style: solid none none;
  border-width: 11px 0 0;
  box-shadow: 5px 7px 1px -2px rgba(0, 0, 0, 0.4);
  color: #222;
  margin: 45px auto 0;
  max-width: 500px;
  min-height: 150px;
  overflow: hidden;
  padding: 15px 25px;
}
body.maintenance-page #maintenance-main-content h2 {
  color: currentColor;
}
body.maintenance-page #webks-bottom {
  background: none repeat scroll 0 0 rgba(255, 255, 255, 0.85);
  color: #888888;
  bottom: 0;
  padding: 20px 2%;
  position: absolute;
  width: 96%;
}
body.maintenance-page #webks-bottom ul.menu {
  text-align: right;
}
body.maintenance-page #webks-bottom .label {
  margin-right: 5px;
}
body.maintenance-page #webks-bottom a {
  color: #888888;
  display: inline-block;
  padding: 0;
}
body.maintenance-page #webks-contact-block {
  float: right;
}
body.maintenance-page #webks-contact-block h2 {
  margin: 0;
  text-align: right;
  font-weight: normal;
}
body.maintenance-page #maintenance-site-name {
  margin: 0 auto;
  text-align: right;
  max-width: 500px;
}
body.maintenance-page #maintenance-page-title {
  font-size: 1.4em;
  font-weight: normal;
  margin-bottom: 0.25em;
}

.block--drowl-brand {
  text-align: center;
  padding-top: 15px;
}
.block--drowl-brand .block-title {
  display: inline-block;
  font-size: 16px;
}
.block--drowl-brand .block-content {
  display: inline-block;
  line-height: 33px;
}
.block--drowl-brand .block-content * {
  vertical-align: middle;
}
.block--drowl-brand:hover {
  color: #83b441;
}
.block--drowl-brand:hover .developer-brand__logo {
  filter: grayscale(0%);
  opacity: 1;
}

.developer-brand__logo {
  width: 120px;
  margin-left: 12px;
  filter: grayscale(100%);
  opacity: 0.6;
  -webkit-transition-property: all;
  transition-property: all;
}

.dev-query {
  background: #cccccc;
  padding: 30px;
}

.contextual-links-region .contextual-links-region .contextual-links-wrapper {
  right: 30px;
}
.contextual-links-region .views-field-contextual-links .contextual-links-wrapper {
  right: 30px;
}

.responsive-views-row-clickable {
  cursor: pointer;
}

.page-admin-dashboard #page {
  max-width: none !important;
}

#webks-dashboard {
  font-family: arial, verdana, sans-serif;
}
#webks-dashboard .panel-pane {
  margin-bottom: 0.75em;
}
#webks-dashboard .pane-title {
  background: #017630;
  color: #fff;
  font-size: 1em;
  padding: 5px;
  margin-top: 0;
}
#webks-dashboard .pane-title a {
  color: #fff;
}
#webks-dashboard .view-filters .views-exposed-widget {
  border: 0 none;
  width: auto;
  padding: 0 5px;
  min-height: 0;
}
#webks-dashboard .view-filters .views-exposed-widget.views-submit-button {
  clear: none;
}
#webks-dashboard .region-two-66-33-second .menu li {
  border-bottom: 1px solid #aaaaaa;
}
#webks-dashboard .region-two-66-33-second .menu li:last-child {
  border-bottom: 0;
}
#webks-dashboard .region-two-66-33-second .node-add-menu li a::before {
  font-family: "iconfont";
  font-weight: normal !important;
  font-style: normal !important;
  margin-right: 4px;
  content: "+";
}
#webks-dashboard .region-two-66-33-second .node-add-menu li a::before {
  color: #5cb85c;
}
#webks-dashboard .region-two-66-33-second .panel-pane {
  background: #cccccc;
}
#webks-dashboard .region-two-66-33-second .panel-pane:not(.pane-menu-block):not(.pane-search) .pane-content {
  padding: 5px;
}
#webks-dashboard .search-form {
  border-top: 3px solid #017630;
  background: #cccccc;
  padding: 5px;
}