// compileCompressed: ../../css/webks.basics/$1.css
// This file is only for styles that are not specific per website - global fixes, improvements & webks brand styles only.
@import "base";

// =============================================================================
//  Branding (webks)
// =============================================================================

a[href="http://www.webks.de"]:hover{ color:$webks-blue !important; }
a[href="http://www.drowl.de"]:hover{ color:$drowl-green !important; }

// -- Colors
.webks-blue{ color:$webks-blue; }
.drowl-green{ color:$drowl-green; }

// -- Maintenance Page
body.maintenance-page{
  background: url("../../images/gear_bg.png") center bottom no-repeat $grey-light !important;
  color: $black;
  height: 100%;
  margin: 0;
  padding: 0;
  h1,
  h2{
    color:$grey-dark;
  }
  #maintenance-branding{
    padding-top:50px;
    text-align:center;
  }
  #maintenance-main-content{
    background-color: $grey-light;
    border-color: $webks-blue;
    border-image: none;
    border-style: solid none none;
    border-width: 11px 0 0;
    box-shadow: $shadow;
    color: $text-color;
    margin: 45px auto 0;
    max-width: 500px;
    min-height: 150px;
    overflow: hidden;
    padding: 15px 25px;
    h2{
      color: currentColor;
    }
    // WHY?
    // text-align: right;
  }
  #webks-bottom {
    background: none repeat scroll 0 0 $white-glass;
    color:$grey-dark;
    bottom: 0;
    padding: 20px 2%;
    position: absolute;
    width: 96%;
    ul.menu{
      text-align:right;
    }
    .label{
      margin-right:5px;
    }
    a{
      color:$grey-dark;
      display:inline-block;
      padding:0;
    }
  }
  #webks-contact-block {
    float:right;
    h2{
      margin:0;
      text-align:right;
      font-weight:normal;
    }
  }
  #maintenance-site-name{
    margin: 0 auto;
    text-align: right;
    max-width: 500px;
  }
  #maintenance-page-title{
    font-size: 1.4em;
    font-weight: normal;
    margin-bottom: .25em;
  }
}

// webks + drowl Branding Block
.block--drowl-brand{
  text-align:center;
  padding-top:15px;
  .block-title{
    display:inline-block;
    font-size:$font-normal;
  }
  .block-content{
    display:inline-block;
    line-height:33px;
    *{
      vertical-align:middle;
    }
  }
  &:hover{
    color:$drowl-green;
    .developer-brand__logo{
      filter: grayscale(0%);
      opacity:1;
    }
  }
}
.developer-brand__logo{
  width:120px;
  margin-left:12px;
  filter: grayscale(100%);
  opacity: .6;
  @include transition-property(all);
}

// =============================================================================
//  Modules
// =============================================================================

// Devel Module
.dev-query {
  background: $grey-light;
  padding: 30px;
}

// Contextual Links (Fix nested context link regions)
.contextual-links-region{
  .contextual-links-region{
    .contextual-links-wrapper{
      right:30px;
    }
  }
  // Custom Views Context Links
  .views-field-contextual-links{
    .contextual-links-wrapper{
      right:30px;
    }
  }
}

// =============================================================================
//  Scripts CSS
// =============================================================================

// Clickable Rows
.responsive-views-row-clickable{ cursor:pointer; }

// =============================================================================
//  Dashboard
// =============================================================================

.page-admin-dashboard #page{
  max-width:none !important;
}

#webks-dashboard{
  // Panels
  font-family: arial, verdana, sans-serif;
  .panel-pane{
    margin-bottom: .75em;
  }
  .pane-title{
    background:$highlight;
    color:$highlight-text-color;
    font-size:1em;
    padding:5px;
    margin-top:0;
    a{
      color:$highlight-text-color;
    }
  }
  // Views
  .view-filters .views-exposed-widget{
    border:0 none;
    width:auto;
    padding:0 5px;
    min-height:0;
    &.views-submit-button{
      clear:none;
    }
  }
  // Sidebar
  .region-two-66-33-second{
    .menu li{
      border-bottom:1px solid $grey;
      &:last-child{
        border-bottom:0;
      }
    }
    .node-add-menu li a{
      @include ico('plus');
      &::before{
        color:$sucess;
      }
    }
    .panel-pane{
      background:$grey-light;
      &:not(.pane-menu-block):not(.pane-search) .pane-content{
        padding:5px;
      }
    }
  }
  // Suche
  .search-form{
    border-top:3px solid $highlight;
    background:$grey-light;
    padding:5px;
  }
}
